import storageService from '../../services/storageService'
import {
  isMobileApp,
  sendMobileAppEvent,
} from '../../utils/mobileAppFunctionality'
import { doRefreshToken } from '../auth'

const runRequestsQueue = async (requestsQueue, http, config, accessToken) => {
  requestsQueue.forEach(({ config, resolve, reject }) => {
    config.headers['Authorization'] = `Bearer ${accessToken}`
    http
      .request(config)
      .then((response) => resolve(response))
      .catch((err) => reject(err))
  })

  await http(config)
}

const refreshTokenMobileApp = async (requestsQueue, http, config) => {
  const newTokenListener = (e) => {
    runRequestsQueue(requestsQueue, http, config, e.detail.access_token)
    window.removeEventListener('MOBILE_APP_TOKEN', newTokenListener)
  }

  const accessToken = storageService.getAccessToken()
  if (accessToken !== undefined && accessToken !== null) {
    window.addEventListener('MOBILE_APP_TOKEN', newTokenListener)
    sendMobileAppEvent('requestNewToken')
  }
}

const refreshTokenDefault = async (requestsQueue, http, config) => {
  const actualRefreshToken = storageService.getRefreshToken()
  const accessToken = storageService.getAccessToken()

  const {
    data: { access_token, refresh_token },
  } = await doRefreshToken(actualRefreshToken, accessToken)

  storageService.setTokens({
    access_token,
    refresh_token,
  })

  await runRequestsQueue(requestsQueue, http, config, access_token)
}

export const refreshToken = async (err, requestsQueue, http) => {
  const { config } = err

  if (config.url === '/auth/signin' || !err.response) return

  try {
    if (isMobileApp()) {
      await refreshTokenMobileApp(requestsQueue, http, config)
    } else {
      await refreshTokenDefault(requestsQueue, http, config)
    }
  } catch (_error) {
    storageService.clearTokens()
    window.location = '/'

    await Promise.reject(_error)
  }
}
