export function getLeadingZeroNumber(value, digits) {
  var zeros = new Array(digits + 1).join('0')
  return (zeros + value).slice(-digits)
}

export function maskEmailAddress(emailAddress) {
  if (!emailAddress) {
    return emailAddress
  }

  function mask(str) {
    var strLen = str.length
    if (strLen > 4) {
      return (
        str.substr(0, 1) +
        str.substr(1, strLen - 1).replace(/\w/g, '*') +
        str.substr(-1, 1)
      )
    }
    return str.replace(/\w/g, '*')
  }

  return emailAddress.replace(
    /([\w.]+)@([\w.]+)(\.[\w.]+)/g,
    function (m, p1, p2, p3) {
      return mask(p1) + '@' + mask(p2) + p3
    }
  )
}

export function queryStringToObject(searchParams) {
  searchParams = searchParams.replace('?', '')
  const result = {}
  searchParams.split('&').forEach((str) => {
    const [key, value] = str.split('=')
    result[key] = decodeURI(value)
  })

  return result
}

export function roundForDisplay(number) {
  return (Math.round(number * 100) / 100).toString()
}

export function hashString(str) {
  let h = 0
  let i = 0
  const l = str.length

  if (l > 0) {
    while (i < l) {
      h = ((h << 5) - h + str.charCodeAt(i++)) | 0
    }
  }

  return h
}

export function langInUrl(url, allLanguages) {
  const langs = new RegExp(
    allLanguages
      .map((t) => {
        return t.lang
      })
      .join('|')
  )

  if (url !== undefined && url.length === 2 && url.indexOf('/') < 0) {
    return url.match(langs)
  }

  if (
    (url !== undefined && url.length === 3 && url.substr(2, 1) === '/') ||
    (url.length > 3 && url.substr(3, 1) === '/')
  ) {
    const langCheck = url.substr(0, 2)
    return langCheck.match(langs)
  }

  return null
}

export const upperCaseTransformer = (event) => {
  const { value } = event?.target || ''
  event.target.value = value?.toUpperCase()
  return event
}
