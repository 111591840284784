export const EnvConfig = {
  API_URL: process.env.GATSBY_KTO_API,
  SITE_URL: process.env.GATSBY_KTO_URL,
  ORYX_LAUNCH_URL: process.env.GATSBY_KTO_ORYX_LAUNCH_URL,
  RELAX_LAUNCH_URL: process.env.GATSBY_KTO_RELAX_LAUNCH_URL,
  SMART_SOFT_URL: process.env.GATSBY_KTO_SMART_SOFT_URL,
  PLAY_TECH_URL: process.env.GATSBY_KTO_PLAY_TECH_URL,
  PLAY_TECH_INSTANCE: process.env.GATSBY_KTO_PLAY_TECH_INSTANCE,
  CMS_API_URL: process.env.WORDPRESS_API_URL,
  TEMPORARY_CMS_API_URL: process.env.TEMPORARY_WORDPRESS_API_URL,
  CMS_URL: process.env.GATSBY_WORDPRESS_URL,
  SPORTS_WALLET_CODE: process.env.GATSBY_SPORTS_WALLET_CODE,
  GATSBY_GTM_CODE: process.env.GATSBY_GTM_CODE,
  GATSBY_SPORTS_URL: process.env.GATSBY_SPORTS_URL,
  GATSBY_SPORTS_URL_V2: process.env.GATSBY_SPORTS_URL_V2,
  GATSBY_GA_ANALYTICS_CODE: process.env.GATSBY_GA_ANALYTICS_CODE,
  GATSBY_ARETONET_ID: process.env.GATSBY_ARETONET_ID,
  GATSBY_TEADS_PIXEL_ID: process.env.GATSBY_TEADS_PIXEL_ID,
  MOCKED_LAUNCH_URL: process.env.GATSBY_KTO_MOCKED_LAUNCH_URL,
  RELAX_PARTNER_ID: process.env.GATSBY_KTO_RELAX_PARTNER_ID,
  GATSBY_GIST_ID: process.env.GATSBY_GIST_ID,
  GATSBY_RECAPTCHA_KEY: process.env.GATSBY_RECAPTCHA_KEY,
  GATSBY_MY_AFF_BRANDED: process.env.GATSBY_MY_AFF_BRANDED,
  GATSBY_MY_AFF_ORGANIC: process.env.GATSBY_MY_AFF_ORGANIC,
  GATSBY_KTO_RELAX_PARTNER: process.env.GATSBY_KTO_RELAX_PARTNER,
  GATSBY_KTO_SMART_SOFT_PORTAL_NAME:
    process.env.GATSBY_KTO_SMART_SOFT_PORTAL_NAME,
  GATSBY_SUPPORT_EMAIL: process.env.GATSBY_SUPPORT_EMAIL,
  GATSBY_GTM_DOMAIN: process.env.GATSBY_GTM_DOMAIN,
  GATSBY_REFER_A_FRIEND_BANNER: process.env.GATSBY_REFER_A_FRIEND_BANNER,
  GATSBY_THEME_COLORS_PRIMARY: process.env.GATSBY_THEME_COLORS_PRIMARY,
  GATSBY_THEME_COLORS_PRIMARY_CONTRAST:
    process.env.GATSBY_THEME_COLORS_PRIMARY_CONTRAST,
  GATSBY_THEME_COLORS_SECONDARY: process.env.GATSBY_THEME_COLORS_SECONDARY,
  GATSBY_THEME_COLORS_TERTIARY: process.env.GATSBY_THEME_COLORS_TERTIARY,
  GATSBY_LIVEAGENT_SRC: process.env.GATSBY_LIVEAGENT_SRC,
  GATSBY_LIVEAGENT_ID: process.env.GATSBY_LIVEAGENT_ID,
  GATSBY_LIVEAGENT_GROUP: process.env.GATSBY_LIVEAGENT_GROUP,
  GATSBY_THEME_LOGO_SIZE_MOBILE: process.env.GATSBY_THEME_LOGO_SIZE_MOBILE,
  GATSBY_THEME_LOGO_SIZE_DESKTOP: process.env.GATSBY_THEME_LOGO_SIZE_DESKTOP,
  GATSBY_LICENSE_XCM_SCRIPT: process.env.GATSBY_LICENSE_XCM_SCRIPT,
  GATSBY_LICENSE_APG_SCRIPT: process.env.GATSBY_LICENSE_APG_SCRIPT,
  GATSBY_COOKIE_PREFIX: process.env.GATSBY_COOKIE_PREFIX,
  GATSBY_UBOS_URL: process.env.GATSBY_UBOS_URL,
  GATSBY_GROWTHBOOK_URL: process.env.GATSBY_GROWTHBOOK_URL,
  GATSBY_GROWTHBOOK_KEY: process.env.GATSBY_GROWTHBOOK_KEY,
  CLOUDFARE_CAPTCHA_SITEKEY: process.env.CLOUDFARE_CAPTCHA_SITEKEY,
  GATSBY_CLOUDFLARE_TURNSTILE_SRC: process.env.GATSBY_CLOUDFLARE_TURNSTILE_SRC,
  GATSBY_SMARTICO_LABEL_KEY: process.env.GATSBY_SMARTICO_LABEL_KEY,
  GATSBY_SMARTICO_BRAND_KEY: process.env.GATSBY_SMARTICO_BRAND_KEY,
  GATSBY_SMARTICO_SCRIPT_URL: process.env.GATSBY_SMARTICO_SCRIPT_URL,
  GATSBY_SEO_CONTENT_STATUS: process.env.GATSBY_SEO_CONTENT_STATUS,
  GATSBY_KAMBI_BOOTSTRAP: process.env.GATSBY_KAMBI_BOOTSTRAP,
  GATSBY_KAMBI_ENV: process.env.GATSBY_KAMBI_ENV,
  GATSBY_ENV: process.env.GATSBY_ENV,
  GATSBY_INTL_LOCALE: process.env.GATSBY_INTL_LOCALE,
  GATSBY_RADAR_KEY: process.env.GATSBY_RADAR_KEY,
}
