import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Text, Title } from '../../atoms/text'
import { Image } from '../../atoms/image'
import { StyledButtonWrapper, StyledContentWrapper } from './styles'
import { Button } from '../../atoms/button'
import { useTimer } from '../../../hooks/useTimer'
import { utcToZonedTime } from 'date-fns-tz'
import { Field } from '../field'
import { useDebounce } from '../../../hooks/useDebounce'
import { If } from '../../atoms/if'

const VerifyEvery60SecondsContent = (props) => {
  const {
    onResend,
    icon,
    onInputFieldDone,
    inputFieldNumOfChars,
    fieldLabel,
    ctaText,
    title,
    description,
    countdownTextPaused,
    countdownTextTicking,
    duration,
    hideInputField = false,
  } = props
  const [disabled, setDisabled] = useState(true)
  const [when60SecsExpires, setWhen60SecsExpires] = useState(new Date())
  const debounceOnInputFieldDone = useDebounce(onInputFieldDone, 1000)
  const { seconds } = useTimer({
    endDate: when60SecsExpires,
    onFinish: () => setDisabled(false),
    immediatellySetTime: true,
  })

  const initTime = () => {
    const now = utcToZonedTime(new Date(), 'UTC')
    now.setSeconds(now.getSeconds() + duration)

    setWhen60SecsExpires(now)
  }

  useEffect(() => {
    initTime()
  }, [])

  const onCtaClick = () => {
    onResend()
    initTime()
    setDisabled(true)
  }

  const onInputChanged = (e) => {
    const { value } = e.target
    if (value.length >= inputFieldNumOfChars) {
      debounceOnInputFieldDone(value)
      e.target.value = value.slice(0, 6)
    }
  }

  return (
    <StyledContentWrapper>
      <Title level={2}>{title}</Title>
      <Image url={icon} />
      <Text>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </Text>
      <If
        condition={onInputChanged && !hideInputField}
        render={() => (
          <Field
            autoCompleteOff
            label={fieldLabel}
            onChange={onInputChanged}
            type="number"
            maxLength={6}
          />
        )}
      />

      <StyledButtonWrapper>
        <Text>
          <span
            dangerouslySetInnerHTML={{
              __html: (disabled
                ? countdownTextTicking
                : countdownTextPaused
              )?.replace('{time}', seconds + 1 || duration),
            }}
          />
        </Text>

        <Button id="resend" expand disabled={disabled} onClick={onCtaClick}>
          {ctaText}
        </Button>
      </StyledButtonWrapper>
    </StyledContentWrapper>
  )
}

VerifyEvery60SecondsContent.defaultProps = {
  duration: 60,
  inputFieldNumOfChars: 6,
}

VerifyEvery60SecondsContent.propTypes = {
  onResend: PropTypes.func,
  icon: PropTypes.any,
  onInputFieldDone: PropTypes.func,
  inputFieldNumOfChars: PropTypes.number,
  fieldLabel: PropTypes.string,
  ctaText: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  countdownTextTicking: PropTypes.string, //! Must add a string with {time} if you want a countdown
  countdownTextPaused: PropTypes.string,
  duration: PropTypes.number,
}

export { VerifyEvery60SecondsContent }
