import {
  ModalTypes,
  CAF_MESSAGE_CODES as CafCodes,
  PaymentTypes,
} from '../utils/constants'
import { getLivenessBankAccount, createBankAccount } from '../adapters/payments'
import { getBankAccountFormFields } from '../atomic-components/molecules/bankAccounts/formFields'
import { FormStyleWrapper } from '../atomic-components/molecules/bankAccounts/formFieldsStyles'
import LivenessIcon from '../images/icon_liveness.svg'
import LivenessFailedIcon from '../images/icon_liveness_failed.svg'
import { useModalContext } from '../context/modalProvider'
import { useTranslation } from '../context/translationProvider'
import {
  isMobileApp,
  sendMobileAppEvent,
} from '../utils/mobileAppFunctionality'

export const usePreDepositModals = () => {
  const { open: openModal, close: closeModal } = useModalContext()
  const { translate } = useTranslation()

  const handleIsMobileApp = () => {
    if (isMobileApp()) {
      sendMobileAppEvent('createBankAccount')
    }

    return isMobileApp()
  }

  const openAddBankAccountModal = (onCancelClose, onSuccessSave) => {
    openModal({
      type: ModalTypes.SIMPLE,
      data: {
        buttonText: translate('payments.modal.addBankAccountButton'),
        keepModalOpen: true,
        onButtonClick: () =>
          openCreateBankAccountModal(onCancelClose, onSuccessSave),
        onCancel: () => onCancelClose(),
        subtitle: translate('payments.modalCreateBankAccounts'),
        text: translate('payments.modal.addBankAccountText'),
      },
    })
  }

  const openCreateBankAccountModal = async (onCancelClose, onSuccessSave) => {
    if (handleIsMobileApp()) {
      closeModal()
      return
    }

    openModal({
      type: ModalTypes.LOADING,
      data: { onCancel: () => onCancelClose() },
    })

    const bankAccountsFormFields = await getBankAccountFormFields(translate)
    openModal({
      type: ModalTypes.FORM,
      title: translate('payments.modalCreateBankAccounts'),
      data: {
        formFields: bankAccountsFormFields,
        formStyleWrapper: FormStyleWrapper,
        onCancel: () => onCancelClose(),
        onSave: (bankAccount) =>
          openLivenessModal(bankAccount, onCancelClose, onSuccessSave),
        saveForm: createBankAccount,
        subtitle: translate(
          'payments.bankAccountKeys.updateModal.accountDetails'
        ),
        text: translate('payments.bankAccountKeys.updateModal.description'),
      },
    })
  }

  const openLivenessModal = async (
    bankAccount,
    onCancelClose,
    onSuccessSave,
    openOrigin,
    customURL = ''
  ) => {
    if (!bankAccount) return

    openModal({
      type: ModalTypes.LOADING,
      data: { onCancel: () => onCancelClose() },
    })

    let urlToVerify = customURL

    if (!customURL) {
      const response = await getLivenessBankAccount(bankAccount.id)
      const bankAccountLiveness = response.data
      urlToVerify = bankAccountLiveness.url
    }

    let modalOriginTitle
    let livenessModalText
    switch (openOrigin) {
      case PaymentTypes.DEPOSIT:
        modalOriginTitle = translate('payments.modal.livenessTitleDeposit')
        livenessModalText = translate('payments.modal.livenessTextDeposit')
        break
      case PaymentTypes.WITHDRAW:
        modalOriginTitle = translate('payments.modal.livenessTitleWithdraw')
        livenessModalText = translate('payments.modal.livenessTextWithdraw')
        break
      default:
        break
    }

    const cafIframeProps = {
      urlToVerify,
      onCancelClose,
      onSuccessSave,
      openOrigin: modalOriginTitle,
      checkURLConnection: !customURL,
    }

    openModal({
      type: ModalTypes.SIMPLE,
      title: translate('payments.modal.livenessVerificationTitle'),
      data: {
        subtitle: translate('payments.modal.livenessVerificationSubtitle'),
        buttonText: translate('common.start'),
        keepModalOpen: true,
        img: LivenessIcon,
        imgOnMiddle: true,
        text: livenessModalText,
        onButtonClick: () => openCafIFrame(cafIframeProps),
        onCancel: () => onCancelClose(),
      },
    })
  }

  const dispatchCloseIframe = async (code, props) => {
    const { onSuccessSave, onCancelClose } = props

    if (code === CafCodes.FINISH_FACE_AUTH) {
      onSuccessSave?.()
      closeModal()
    } else if (code === CafCodes.INVALID) {
      openFailedLivenessModal(props)
    } else {
      onCancelClose?.()
      closeModal()
    }
  }

  const openCafIFrame = (props) => {
    const { urlToVerify, onCancelClose, openOrigin, checkURLConnection } = props
    openModal({
      type: ModalTypes.CAFIFRAME,
      data: {
        urlToVerify,
        closeIFrame: (messageCode) => dispatchCloseIframe(messageCode, props),
        onCancel: () => onCancelClose(),
        openOrigin,
        checkURLConnection,
      },
    })
  }

  const openFailedLivenessModal = (props) => {
    const { onCancelClose, openOrigin } = props

    openModal({
      type: ModalTypes.CONFIRMATION,
      title: openOrigin,
      data: {
        confirmButtonText: translate('common.tryAgain'),
        img: LivenessFailedIcon,
        imgOnMiddle: true,
        keepModalOpen: true,
        onConfirmation: () => openCafIFrame(props),
        onCancel: () => onCancelClose(),
        subtitle: translate('payments.modal.failedVerificationSubtitle'),
        text: translate('payments.modal.livenessText'),
      },
    })
  }

  return {
    openAddBankAccountModal,
    openCreateBankAccountModal,
    openLivenessModal,
    openCafIFrame,
  }
}
