import { Container } from '@techmobilt/ui-components'
import React from 'react'
import { Text } from '../../atoms/text'
import packageJson from '../../../../package.json'
import { useTranslation } from '../../../context/translationProvider'

export const FooterVersion = () => {
  const { translate } = useTranslation()
  return (
    <Container style={{ paddingBottom: '4.875rem' }}>
      <Text noMargin small textAlign="center">
        {`${translate('version')} ${packageJson?.version}`}
      </Text>
    </Container>
  )
}
