import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.8em;
  height: 100%;

  * {
    text-align: center;
  }

  ${themeMedia.desktop`
    br {
      display: none;
    }
  `}
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.9em;
  margin: auto 0 1.5em;
`